<template>
    <div class="postDetails oepnX">
        <HeadBar titleName="帖子详情"></HeadBar>
        <div class="content">
            <PullRefresh v-if="!isLoading" :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"
                         @onRefresh="onRefresh"  :error="error" :is-higeht-max="true"
                         :adv-list="advList" :hasAdvPagination="true" className="VideoDetailsPage">
                        <PostAtlas :isShowHead="true" :hiddenOtp="true" :postInfo="videoInfo" @focusOn="focusOn" />
<!--                        <PostAtlas  v-if="videoInfo.newsType == 'COVER_SP' || videoInfo.newsType == 'COVER'" :isShowHead="true" :hiddenOtp="true" :postInfo="videoInfo" @focusOn="focusOn" />-->
<!--                        <PostItem v-else :isShowHead="true" :hiddenOtp="true" :postInfo="videoInfo" @focusOn="focusOn" />-->

                  <!-- <div class="communityContent" v-if="videoInfo">
                      <div class="communityHead">
                          <div class="userContent">
                              <div class="portrait">
                                  <ImgDecypt class="avatarImg" :src="videoInfo.publisher.portrait" round />
                              </div>
                              <div class="userInfo">
                                  <div class="userName">
                                      <span>{{ videoInfo.publisher.name }}</span>
                                      <img v-if="videoInfo.publisher.vipLevel == 1" class="vip" src="@/assets/png/userVip.png">
                                  </div>
                                  <div class="releaseTime">
                                      <span>{{videoInfo.reviewAt | commentTime}}</span>
                                  </div>
                              </div>
                          </div>
                          <div class="focusBtn" v-if="!videoInfo.publisher.hasFollowed" @click="focus(videoInfo.publisher)">
                              <span>关注</span>
                          </div>
                          <div class="focusBtn alreadyFocus" v-else @click="focus(videoInfo.publisher)">
                              <span>已关注</span>
                          </div>
                      </div>
                      <div class="communityText">
                          <span>{{videoInfo.title}}</span>
                      </div>

                      <div class="imgContent">
                          <div class="imgItem" v-for="(itemCover,indexCover) in videoInfo.seriesCover.slice(0,9)" :key="indexCover" @click="previewImg(videoInfo.seriesCover,index)">
                              <ImgDecypt :src="itemCover" />
                          </div>
                      </div>
                      <div class="tags">
                          <div class="tag" v-for="(itemTag,indexTag) in videoInfo.tags" :key="indexTag">
                              <span># {{itemTag.name}}</span>
                          </div>
                      </div>
                  </div> -->
                  <div class="commentsContent">
                      <div class="commentsHead">
                          <div>评论</div>
<!--                          <div class="commentTotle">{{msgTotle}}</div>-->
                      </div>
                      <div class="qiuckSearchBox flex-aic" v-if="quickSearchObj.title">
                       <span>大家都在搜索：</span>
                       <van-notice-bar
                         v-if="quickSearchObj.title"
                         @click.stop="jumpAdv(quickSearchObj)"
                         color="#F92B6C"
                         background="transparent"
                         scrollable
                         :text="quickSearchObj.title"
                       />
                      </div>
                      <div class="commentsContentList" v-if="commentList.length > 0">
                          <div class="commentBox" v-for="item in commentList" :key="item.id">
                              <div class="commentInfo">
                                  <div @click.stop="openPersonal(item)">
                                      <ImgDecypt class="avatar" round :src="item.userPortrait" :key="item.userPortrait"/>
                                  </div>

                                  <div class="info">
                                      <div class="name">
                                          <span>{{ item.userName }}</span>
                                          <img class="hotImg" v-if="item.isHotComment" src="@/assets/png/isHotComment.png" />
                                          <img class="hotImg" v-if="item.isTop" src="@/assets/png/commentTop.png" />
<!--                                          <svg-icon :iconClass="item.gender == 'male' ? 'maleMsg' : 'female'"></svg-icon>-->
                                      </div>
                                      <div class="text msgContent" v-if="!item.isDelete">
                                          <div style="width: 100%">
                                              <CommentJump
                                                  class="commit_content"
                                                  :text="item.content"
                                                  :keyword="item.searchKeyword"
                                                  :linkType="item.linkType"
                                                  :linkStr="item.linkStr"
                                              />
                                          </div>
                                      </div>
                                      <div class="text" style="color: rgb(153, 153, 153)" v-else>内容已被删除</div>
                                      <div class="address" v-if="!item.isTop && !item.isOfficial">
                                          <div class="createdAt">
                                              <span>{{ formatDate(item.createdAt) }}</span>
                                          </div>

                                          <div class="btnBox" v-if="!item.linkType">
                                                <span @click.stop="reply(item, null)">
                                                    <svg-icon class="community_info" iconClass="community_info"/>
                                                </span>

                                              <span @click.stop="clickZan(item)">
                                                    <svg-icon class="like icon" v-if="item.isLike" iconClass="postLikeedIcon"/>
                                                    <svg-icon class="like icon" v-else iconClass="postLikeIcon"/>
                                                </span>

                                          </div>
                                      </div>
<!--                                      <div v-else>-->
<!--                                          <span style="color: rgb(153, 153, 153);font-size: 12px">&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;此评论系统生成 无法回复</span>-->
<!--                                      </div>-->

                                      <div class="commentBox" style="border-bottom: none">
                                          <div class="commentInfo" v-if="item.Info && item.Info.length > 0"
                                               style="margin-bottom: 10px">
                                              <ImgDecypt class="avatar" round :src="item.Info[0].userPortrait"
                                                         :key="item.Info[0].userPortrait"/>
                                              <div class="childInfo">
                                                  <div class="name">
                                                      <span>{{ item.Info[0].userName }}</span>
<!--                                                      <svg-icon-->
<!--                                                          :iconClass="item.Info[0].gender == 'male' ? 'male' : 'female'"></svg-icon>-->
                                                      <svg-icon
                                                          v-if="item.Info[0].toUserID != 0 && item.Info[0].toUserID != null"
                                                          class="rightCommIcon" iconClass="rightCommIcon"></svg-icon>
                                                      <span v-if="item.Info[0].toUserID">{{ item.Info[0].toUserName }}</span>
                                                  </div>
                                                  <div class="text msgContent" v-if="!item.Info[0].isDelete">
                                                      <div style="width: 100%">
                                                          <CommentJump
                                                              class="commit_content"
                                                              :text="item.Info[0].content"
                                                              :keyword="item.Info[0].searchKeyword"
                                                              :linkType="item.Info[0].linkType"
                                                              :linkStr="item.Info[0].linkStr"
                                                          />
                                                      </div>
                                                  </div>
                                                  <div class="text" style="color: rgb(153, 153, 153)" v-else>内容已被删除
                                                  </div>
                                                  <div class="address">
                                                      <div class="createdAt">
                                                    <span>{{
                                                        formatDate(item.Info[0].createdAt)
                                                        }}</span>
                                                      </div>

                                                      <div class="btnBox" v-if="!item.Info[0].linkType">

                                                         <span @click.stop="reply(item.Info[0], item)">
                                                            <svg-icon class="community_info" iconClass="community_info"/>
                                                        </span>

                                                              <span @click.stop="clickZan(item.Info[0])">
                                                            <svg-icon class="like icon" v-if="item.Info[0].isLike" iconClass="postLikeedIcon"/>
                                                            <svg-icon class="like icon" v-else iconClass="postLikeIcon"/>
                                                        </span>

                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div v-if="item.isShow">
                                              <div class="commentInfo" v-for="(subComment) in item.Info.slice(1)"
                                                   :key="subComment.id" style="margin-bottom: 10px">
                                                  <ImgDecypt class="avatar" round :src="subComment.userPortrait"
                                                             :key="item.userPortrait"/>
                                                  <div class="childInfo">
                                                      <div class="name">
                                                          <span>{{ subComment.userName }}</span>
<!--                                                          <svg-icon-->
<!--                                                              :iconClass="subComment.gender == 'male' ? 'male' : 'female'"></svg-icon>-->
                                                          <svg-icon
                                                              v-if="subComment.toUserID != 0 && subComment.toUserID != null"
                                                              class="rightCommIcon" iconClass="rightCommIcon"></svg-icon>
                                                          <span
                                                              v-if="subComment.toUserID != 0 && subComment.toUserID != null">{{
                                                                  subComment.toUserName
                                                              }}</span>
                                                      </div>
                                                      <div class="text msgContent" v-if="!subComment.isDelete">
                                                          <div style="width: 100%">
                                                              <CommentJump
                                                                  class="commit_content"
                                                                  :text="subComment.content"
                                                                  :keyword="subComment.searchKeyword"
                                                                  :linkType="subComment.linkType"
                                                                  :linkStr="subComment.linkStr"
                                                              />
                                                          </div>
                                                      </div>
                                                      <div class="text" style="color: rgb(153, 153, 153)" v-else>
                                                          内容已被删除
                                                      </div>
                                                      <div class="address">
                                                          <div class="createdAt">
                                                        <span>{{
                                                            formatDate(subComment.createdAt)
                                                            }}</span>
                                                          </div>

                                                          <div class="btnBox" v-if="!subComment.linkType">

                                                                <span @click.stop="reply(subComment, item)">
                                                                    <svg-icon class="community_info" iconClass="community_info"/>
                                                                </span>

                                                                      <span @click.stop="clickZan(subComment)">
                                                                    <svg-icon class="like icon" v-if="subComment.isLike" iconClass="postLikeedIcon"/>
                                                                    <svg-icon class="like icon" v-else iconClass="postLikeIcon"/>
                                                                </span>

                                                          </div>

                                                      </div>
                                                  </div>
                                              </div>
                                          </div>

                                          <Loading
                                              class="sec-loading"
                                              v-if="commentParams.currentFirstItem.id == item.id && commentParams.currentLoading"
                                          />
                                          <div
                                              class="load-more"
                                              v-else-if="
                      !commentParams.currentLoading &&
                      commentParams.currentFirstItem.id == item.id &&
                      commentParams.currentHasNext
                    "
                                              @click.stop="loadMoreSubComment(item)"
                                          >
                                              — 点击加载更多评论
                                          </div>
                                      </div>
                                  </div>
                                  <img class="godMsg" v-if="item.isGodComment" src="@/assets/png/godMsg.png"/>
                              </div>
                              <div class="expand-box" @click.stop="onShow(item)" v-if="item.commCount && item.commCount > 1">
                                  {{ item.isShow ? '— 收起' : `— 展开${item.commCount - 1}条回复` }}
                                  <svg-icon
                                      iconClass="expand"
                                      :class="[{ 'rotate-target': item.isShow }, { 'rotate-burden-target': !item.isShow }]"
                                  />
                              </div>
                          </div>

<!--                          <div class="msgItem" v-for="item in commentList" :key="item.id">-->
<!--                              <div class="msgTopBox">-->
<!--                                  <div @click.stop="jumpUserPage(item)">-->
<!--                                      <ImgDecypt class="avatar" :src="item.userPortrait" :key="item.userPortrait" round />-->
<!--                                  </div>-->
<!--                                  <div class="infoBox">-->
<!--                                      <div class="uName" @click.stop="replyMsg(item)">{{item.userName}}</div>-->
<!--                                      <div class="dateInfo">{{item.createdAt | commentTime}}</div>-->
<!--                                  </div>-->
<!--                                  <div class="likeBox" @click.stop="likeComment(item)">-->
<!--                                      <svg-icon class="likeIcon" :iconClass="item.isLike ? 'redHeart' : 'grey_heart'" />-->
<!--                                      <div>{{item.likeCount}}</div>-->
<!--                                  </div>-->
<!--                              </div>-->
<!--                              <div class="msgContent">{{item.content}}</div>-->
<!--                              <div class="msgLevel2" v-if="item.Info && item.Info.length > 0">-->
<!--                                  <div class="msgBoxLv2" v-for="i in item.Info" :key="i.id">-->
<!--                                      <div class="nameLv2">{{i.userName}}</div>-->
<!--                                      <div class="msgLv2">{{i.content}}</div>-->
<!--                                  </div>-->
<!--                                  <div class="msgNum" @click.stop="jumpDetails(item)">共{{item.commCount || 0}}条回复></div>-->
<!--                              </div>-->
<!--                          </div>-->
                      </div>
                      <div class="nodataBox">空空如也～</div>
                  </div>
            </PullRefresh>
            <Loading v-else />
        </div>

<!--        <div class="tabBar" @click="showInput">-->
        <div class="tabBar">
            <div class="inputBox">
                <van-field ref="commentInputBoxRef" class="newInput" v-model="commentParams.content" :border="false"
                           :placeholder="placeholder" @blur="changBlur" />
                <div class="sendBtn" :class="{activeBtn: msgVal}" @mousedown="handleMousedown"></div>
            </div>
<!--            <div class="comments">-->
<!--                <span>发条有爱的评论吧</span>-->
<!--                <img src="@/assets/png/commentsEditor.png">-->
<!--            </div>-->
<!--            <div class="placeholder"></div>-->
        </div>

<!--        <van-popup class="inputPop" v-model="isShowInput" position="bottom" @close="closeInput">-->
<!--            <div class="inputBox">-->
<!--                <van-field class="newInput" ref="newInput" v-model="msgVal" :border="false"-->
<!--                           :placeholder="placeholder" />-->
<!--                <div class="sendBtn" :class="{activeBtn: msgVal}" @click="sendMsg">发送</div>-->
<!--            </div>-->
<!--        </van-popup>-->
    </div>
</template>

<script>
import PostAtlas from "@/components/PostAtlas";
// import PostItem from "@/components/PostItem";
import HeadBar from "@/components/HeadBar";
import {queryVidInfo} from "@/api/community";
import ImgDecypt from "@/components/ImgDecypt";
import {cancleZan, careOrcancle, queryCommentList, zan} from "@/api/user";
import PullRefresh from "@/components/PullRefresh";
import Loading from "@/components/Loading";
import {getSecCommentList, sendComment, sendV2Comment, thumbsDownComment, thumbsUpComment} from "@/api/mine";
import {Toast} from "vant";
import CommentJump from "@/components/CommentJump";
import {formatDate} from "../../../utils";
import {jumpAdv} from "@/utils/getConfig";

export default {
  name: "PostDetails",
    components:{
        HeadBar,ImgDecypt,PullRefresh,Loading,PostAtlas,CommentJump,
        // PostItem
    },data(){
      return {
          videoID:null,
          videoInfo:null,
          commentList:[],
          isLoading: true,
          loading: true,
          refreshing: false,
          finished: false,
          isNoData: false,
          error: false,
          advList: [],
          isShowInput: false,
          placeholder: "我怀疑你也想开车",
          msgVal: "",
          cid: undefined,
          userId: undefined,
          pageNumber: 1,
          pageSize: 10,
          msgTotle: 0,
          currMsgItem: "",
          commentParams: {
              objID: '', // 帖子ID,
              level: 1, // 评论级别，1: 一级评论;2: 二级评论
              content: '', // 评论内容
              cid: '', // 一级评论的ID
              userID: '', // 一级评论的用户ID
              currentFirstItem: {}, // 当前一级评论对象
              currentHasNext: false, // 当前评论是否还有下一页数据
              currentLoading: false, // 当前二级评论的loading
              currentShowInputId: '', // 当前显示的输入框id
              rid: "",
              toUserID: "",
          }, // 评论参数
          secPageNumber: 1, // 二级评论页码
          secPageSize: 10, // 二级评论一页的数量
          quickSearchObj: {},
      }
    },
    mounted() {
        let query = this.$route.query;
        if (query){
            this.videoID = query.videoID;
            this.getVidInfo();
            this.getCommentList();
        }
       window.jumpKeywords = this.jumpKeywords;
    },
    methods:{
     jumpAdv,
     formatDate,
        jumpKeywords(str) {
         const strArr = str.split(',');
         const link = strArr[0];
         const id = strArr[1];
         let obj = {
          link: link,
          id: id,
         };
         jumpAdv(obj);
        },
        openPersonal(item) {
            // this.$router.push({path: '/userHomePage', query: {uid: item.userID}})
            // this.$emit('close')
        },
        async onShow(comment) {
            /**
             * 展开与收起
             */
            if (comment.isShow) {
                this.$nextTick(()=>{
                    comment.isShow = false;
                    this.$forceUpdate()
                })
            } else {
                this.commentParams.currentHasNext = true; // 初始化当前评论默认有下一页
                this.commentParams.currentFirstItem = comment;
                this.commentParams.currentLoading = true;
                this.secPageNumber = 1;
                comment.isShow = true;
                await this.getSecondList(comment);
            }
        },
        async loadMoreSubComment(comment) {
            /**
             * 获取更多二级评论
             * @type {boolean}
             */
            this.commentParams.currentLoading = true;
            this.secPageNumber++;
            await this.getSecondList(comment);
        },
        reply(item, parentItem) {
            /**
             * 二级评论输入框显示事件
             */
            if (parentItem != null) {
                this.commentParams.currentFirstItem = parentItem;
                this.commentParams.rid = item.id;
                this.commentParams.toUserID = item.userID;
                this.commentParams.cid = parentItem.id;
            } else {
                this.commentParams.currentFirstItem = item;
                this.commentParams.rid = "";
                this.commentParams.toUserID = "";
                this.commentParams.cid = item.id;
            }
            this.commentParams.objID = item.objID;
            this.commentParams.userID = item.userID;
            this.commentParams.level = 2;
            this.placeholder = `回复@${item.userName}`;
            this.$refs.commentInputBoxRef.focus();
        },
        focusOn(data) {
            this.videoInfo.publisher.hasFollowed = data.focusSta;
        },
        async getVidInfo() {
            /**
             * 帖子详情
             */
            let req = {
                videoID:this.videoID
            }
            let res = await this.$Api(queryVidInfo, req);

            this.isLoading = false;

            if (res && res.code == 200) {
                this.videoInfo = res.data;
            }
        },previewImg(list, index){
            /**
             * 图片预览
             */
            this.$router.push({
                name: "PicturePreview",
                params: {
                    imgs: list,
                    index: index
                },
            });
        },async giveLike(item) {
            let req = {
                objID: item.id,
                type: item.sourceURL?'video':'image',
            }

            if (item.vidStatus.hasLiked) {
                /**
                 * 取消点赞
                 */
                let res = await this.$Api(cancleZan, req);
                if(res && res.code == 200){
                    item.vidStatus.hasLiked = false;
                    this.$toast({
                        message: '取消点赞',
                        position: 'top',
                    });
                }

            } else {
                /**
                 * 点赞
                 */
                let res = await this.$Api(zan, req);
                if(res && res.code == 200){
                    item.vidStatus.hasLiked = true;
                    this.$toast({
                        message: '点赞成功',
                        position: 'top',
                    });
                }
            }
        },async focus(item) {
            /**
             * 关注/取消关注
             */
            let req = {
                followUID: item.uid,
                isFollow: item.hasFollowed,
            }
            let res = await this.$Api(careOrcancle, req);

            if(res && res.code == 200){
                if(!item.hasFollowed){
                    item.hasFollowed = true;
                    this.$toast({
                        message: '关注成功',
                        position: 'top',
                    });
                }else{
                    item.hasFollowed = false;
                    this.$toast({
                        message: '取消关注',
                        position: 'top',
                    });
                }
            }
        },async getCommentList(type) {
            /**
             * 评论列表
             */
            let req = {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                curTime: new Date().toISOString(),
                objID: this.videoID,
                objType: 'video',
            }
            let res = await this.$Api(queryCommentList, req);

            this.loading = false;
            this.refreshing = false;
            this.isLoading = false;

            try {
                if (res && res.code == 200) {

                    if(res.data.list){
                        this.msgTotle = res.data.total;
                        // this.msgTotle = res.data.lfCount;
                        // console.log(res.data);

                        if(type == "refresh"){
                            this.commentList = res.data.list;
                        }else {
                            this.commentList = this.commentList.concat(res.data.list);
                        }
                    }

                    if (this.pageNumber === 1 && res.data.quickSearchList && res.data.quickSearchList.length) {
                     res.data.quickSearchList.map((item) => {
                      if (item.type === 2) {
                       this.quickSearchObj = item;
                      } else {
                       // 评论对象
                       const _reg = new RegExp(item.searchKeyword, 'g');
                       let obj = {
                        id: item.id,
                        content: item.title.replace(
                          _reg,
                          `<span style="color:rgba(86, 250, 246, 1)" onclick="jumpKeywords('${item.link},${item.id}')">${item.searchKeyword}</span>`,
                        ),
                        userName: item.userName,
                        userID: item.userID,
                        Info: [],
                        userPortrait: item.userPortrait,
                        isOfficial: true,
                       };
                       this.commentList.splice(1, 0, obj);
                      }
                     });
                    }

                    if (this.pageNumber === 1 && this.commentList.length === 0) {
                        this.isNoData = true;
                    }
                    if (!res.data.hasNext || (res.data.list && res.data.list.length < req.pageSize)) {
                        this.finished = true;
                    }
                } else {
                    this.error = true;
                }
            } catch (error) {
                this.error = true;
                this.loading = false;
            }
        },onLoad(){
            this.loading = true;
            if (this.error) {
                this.error = false;
            } else {
                this.pageNumber++
            }
            this.getCommentList();
        },onRefresh() {
            this.pageNumber = 1;
            this.refreshing = true;
            this.finished = false;
            this.isNoData = false;
            this.getCommentList('refresh');
        },jumpUserPage(item) {
            this.$router.replace({path: "/userHomePage", query:{
                    uid: item.userID
                }})
            this.$store.commit("user/SET_COMMENTPOP", {show: false, info: {}})
            if (this.$route.path == "/userHomePage") {
                this.reload()
            }
        },async clickZan(item) {
            /**
             * 评论点赞
             * @type {{objID, type: string}}
             */
            let req = {
                objID: item.id,
                type: "comment"
            }
            this.$store.commit('app/SET_LOADING', true);
            try {
                let res = await this.$Api(item.isLike ? thumbsDownComment : thumbsUpComment, req);
                this.$store.commit('app/SET_LOADING', false);
                if (res.code === 200) {
                    if (item.isLike) {
                        item.likeCount = item.likeCount - 1 || 0;
                    } else {
                        item.likeCount = item.likeCount + 1;
                    }
                    item.isLike = !item.isLike;

                    Toast(item.isLike ? "点赞成功" : "取消点赞");
                } else {
                    Toast(res.tip || "操作失败，请稍后再试");
                }
            } catch (error) {
                this.$store.commit('app/SET_LOADING', false);
                Toast("操作失败，请稍后再试");
            }
        },
        async getSecondList(comment) {
            /**
             * 获取二级评论
             */
            let req = {
                objID: comment.objID,
                cmtId: comment.id,
                fstID: comment.Info[0].id, // 第一条评论的ID
                curTime: new Date().toISOString(),
                pageNumber: this.secPageNumber,
                pageSize: this.secPageSize,
                objType: 'video',
            };

            try {
                let res = await this.$Api(getSecCommentList, req);
                this.commentParams.currentLoading = false;
                if (res.data.list && res.data.list.length > 0) {
                    let commentNo = [comment.Info[0]];
                    if(this.secPageNumber == 1){
                        comment.Info = commentNo.concat(res.data.list);
                    }else{
                        comment.Info = commentNo.concat(comment.Info.concat(res.data.list));
                    }
                }
                if (!res.data.hasNext) {
                    this.commentParams.currentHasNext = false;
                }
            } catch (error) {
                this.error = true;
                this.loading = false;
            }
        }, showInput() {
            this.level = 1;
            this.isShowInput = true;
            this.$nextTick(() => {
                if (this.$refs.newInput) {
                    this.$refs.newInput.focus()
                }
            })
        }, closeInput() {
            this.cid = undefined;
            this.userId= undefined;
            this.msgVal = "";
            this.level = 1;
        },changBlur() {
      window.scroll(0, 0); //失焦后强制让页面归位
     },
     async handleMousedown(event) {
      event.preventDefault();
      this.$refs.commentInputBoxRef.blur();
      await this.sendMsg();
     },async sendMsg() {
            if(!this.commentParams.content){
                this.$toast('请输入评论');
                return;
            }
            let req = {};
            if (this.commentParams.level == 1) {
                // 一级评论参数
                req = {
                    objID: this.videoID,
                    level: 1,
                    content: this.commentParams.content,
                    objType: 'video',
                };
            } else {
                // 二级评论参数
                if (this.commentParams.toUserID) {
                    req = {
                        objID: this.videoID,
                        level: 2,
                        content: this.commentParams.content,
                        cid: this.commentParams.cid,
                        userID: this.commentParams.userID,
                        rid: this.commentParams.rid,
                        toUserID: this.commentParams.toUserID,
                         objType: 'video',
                    };
                } else {
                    req = {
                        objID: this.videoID,
                        level: 2,
                        content: this.commentParams.content,
                        cid: this.commentParams.cid,
                        userID: this.commentParams.userID,
                        objType: 'video',
                    };
                }
            }
            this.$store.commit('app/SET_LOADING', true);
            try {
                let res = await this.$Api(sendComment, req);
                console.log(res);
                this.$store.commit('app/SET_LOADING', false);
                if(res.code == 200){
                    this.commentParams.content = ''; // 评论输入框内容清空
                    this.lfCount++; // 评论+1
                    if (this.commentParams.level == 1) {
                        this.isNoData = false;
                     if (this.$store.getters.isVip) {
                      this.commentList.unshift(res.data);
                     }
                    } else if (this.commentParams.level == 2) {
                        this.commentList = this.commentList.map((item) => {
                            if (item.id == this.commentParams.currentFirstItem.id) {
                                item.isShow = true;
                             if (this.$store.getters.isVip) {
                              item.Info.unshift(res.data);
                             }
                            }
                            return item;
                        });
                    }
                    this.clearReply();
                    this.$toast('评论成功');
                }else{
                    this.$toast(res.tip);
                }
            } catch (error) {
                this.$store.commit('app/SET_LOADING', false);
                Toast("评论失败，请稍后再试");
            }
            // let req = {
            //     cid: this.cid,
            //     content: this.msgVal,
            //     level: this.level,
            //     objID: this.videoID,
            //     cmtType: "media"
            // }
            //
            // if (!req.content) return;
            // this.$store.commit('app/SET_LOADING', true);
            // try {
            //     let res = await this.$Api(sendComment, req);
            //     this.$store.commit('app/SET_LOADING', false);
            //     if (res.code === 200) {
            //         if (this.currMsgItem) {
            //             this.currMsgItem.Info.unshift(res.data);
            //             this.currMsgItem.commCount = this.currMsgItem.commCount + 1;
            //         } else {
            //             this.commentList.unshift(res.data);
            //
            //         }
            //         this.msgTotle = this.msgTotle + 1;
            //         this.isShowInput = false;
            //         Toast("评论成功");
            //     } else {
            //         Toast(res.tip || "评论失败，请稍后再试");
            //     }
            // } catch (error) {
            //     this.$store.commit('app/SET_LOADING', false);
            //     Toast("评论失败，请稍后再试");
            // }
        },
      clearReply() {
       this.commentParams.currentFirstItem = {};
       this.commentParams.objID = "";
       this.commentParams.cid = "";
       this.commentParams.userID = "";
       this.commentParams.level = 1;
       this.placeholder = `我怀疑你也想开车`;
      },
        // 回复-显示输入框
        replyMsg(item) {
            this.placeholderText = `回复 @${item.userName}`;
            this.cid = item.id;
            this.userId = item.userId;
            this.level = 2;
            this.isShowInput = true;
            this.currMsgItem = item;
            this.$nextTick(() => {
                if (this.$refs.newInput) {
                    this.$refs.newInput.focus()
                }
            })
        },
        jumpDetails(item) {
            this.$router.push({
                path: '/commentDetails',
                query: {
                    msgInfo: this.encodeBase64(encodeURI(JSON.stringify(item)))
                }
            })
        },
        // 转base64
        encodeBase64(str) {
            let base64Str = Buffer.from(str, 'utf-8').toString('base64');
            return base64Str;
        },
    },
    watch:{
        '$route.query': {
            immediate: true,
            handler(newQuery, oldQuery) {
                if(newQuery && oldQuery){
                    if(this.videoID != newQuery.videoID){
                        this.isLoading = true;
                        this.videoID = newQuery.videoID;
                        this.getVidInfo();
                        this.getCommentList('refresh');
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .postDetails{
        background: #F5F5F5;

        /deep/ .postItem{
            padding: 0 16px;
         }

        .content{
            height: calc(100vh - 44px - 60px);
            // padding: 0 16px;
            background: #F5F5F5;
            .commentBox {
                margin-top: 15px;
                border-bottom: solid rgb(229, 229, 229) 1px;

                .commentInfo {
                    display: flex;
                    width: 100%;

                    .godMsg {
                        position: absolute;
                        right: 55.8px;
                        width: 60.2px;
                        height: 60.2px;
                        z-index: 10;
                    }

                    .avatar {
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                        margin-right: 12px;
                        flex-shrink: 0;
                    }

                    .info {
                        width: calc(100vw - 74px);
                        flex-shrink: 0;
                        color: rgb(51, 51, 51);

                        .name {
                            color: rgb(51, 51, 51);
                            font-size: 12px;

                             .hotImg {
                              width: 44px;
                              height: 22px;
                              vertical-align: middle;
                             }

                            span {
                                margin-right: 4px;
                            }

                            svg {
                                width: 10px;
                                height: 10px;
                            }
                        }

                        .msgContent {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .zanBox {
                                position: absolute;
                                right: 0;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                z-index: 20;

                                .zanIcon {
                                    height: 22px;
                                    width: 22px;
                                    margin-bottom: 5px;
                                }

                                .zanNumber {
                                    font-size: 12px;
                                }
                            }
                        }

                        .text {
                            //width: 300px;
                            //text-overflow: ellipsis;
                            //word-wrap: break-word;
                            //white-space: pre-wrap;

                            //width: 100%;
                            //overflow: hidden;
                            white-space: pre-wrap;
                            word-wrap: break-word;
                            text-overflow: ellipsis;

                            font-size: 14px;
                            //margin: 4px 0 7px;
                            margin-top: 5px;
                            color: rgb(51, 51, 51);
                        }

                        .address {
                            //font-size: 10px;
                            //color: rgb(153, 153, 153);
                            margin-top: 8px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .createdAt {
                                font-size: 12px;
                                color: rgb(153, 153, 153);
                            }

                            //.btn {
                            //  font-size: 10px;
                            //  color: rgb(255, 255, 255);
                            //  margin-left: 6px;
                            //}

                            .btnBox {
                                display: flex;
                                align-items: center;


                                .icon {
                                    width: 20px;
                                    height: 20px;
                                }

                                .community_info {
                                    width: 14px;
                                    height: 14px;
                                }

                                .like {
                                    margin-left: 18px;
                                }
                            }
                        }
                    }

                    .childInfo {
                        width: calc(100vw - 126px);
                        flex-shrink: 0;
                        color: rgb(102, 102, 102);

                        .name {
                            color: rgb(153, 153, 153);
                            font-size: 12px;
                            display: flex;
                            align-items: center;

                            span {
                                margin-right: 4px;
                            }

                            svg {
                                width: 10px;
                                height: 10px;
                            }

                            .rightCommIcon {
                             width: 24px;
                             height: 18px;
                             margin-left: 2px;
                             margin-right: 3px;
                            }
                        }

                        .msgContent {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .zanBox {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                z-index: 20;

                                .zanIcon {
                                    height: 22px;
                                    width: 22px;
                                    margin-bottom: 5px;
                                }

                                .zanNumber {
                                    font-size: 12px;
                                }
                            }
                        }

                        .text {
                            //width: 300px;
                            //text-overflow: ellipsis;
                            //word-wrap: break-word;
                            //white-space: pre-wrap;

                            //width: 100%;
                            //overflow: hidden;
                            white-space: pre-wrap;
                            word-wrap: break-word;
                            text-overflow: ellipsis;

                            font-size: 14px;
                            //margin: 4px 0 7px;
                            margin-top: 5px;
                            color: rgb(51, 51, 51);
                        }

                        .address {
                            font-size: 10px;
                            color: rgb(153, 153, 153);
                            margin-top: 8px;

                            .btn {
                                font-size: 10px;
                                color: rgb(255, 255, 255);
                                margin-left: 6px;
                            }
                        }
                    }
                }

                .expand-box {
                    font-size: 12px;
                    color: rgb(153, 153, 153);
                    font-weight: 300;
                    margin: 8px 0;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;

                    > svg {
                        height: 12px;
                        width: 12px;
                        margin-left: 5px;
                    }
                }

                // 旋转180度
                .rotate-target {
                    transform: rotate(180deg);
                    transition: transform 0.3s;
                }

                // 旋转0度
                .rotate-burden-target {
                    transform: rotate(0deg);
                    transition: transform 0.3s;
                }
            }

            .communityContent{
                padding: 18px 0 12px 0;

                //border-top: solid rgb(43,39,74) 1px;
                //border-bottom:  solid rgb(43,39,74) 1px;

                .communityHead{
                    padding: 0 12px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .portrait{
                        width: 44px;
                        height: 44px;
                        background: rgb(255,255,255);
                        border-radius: 50%;
                    }

                    .userContent{
                        display: flex;
                        align-items: center;

                        .userInfo{
                            margin-left: 8px;

                            .userName{
                                font-size: 15px;
                                color: rgb(0,0,0);
                                display: flex;
                                align-items: end;

                                .vip{
                                    width: 24px;
                                    height: 12px;
                                    margin-left: 9px;
                                }
                            }

                            .releaseTime{
                                font-size: 12px;
                                color: rgb(163,160,181);
                                margin-top: 6px;
                            }
                        }
                    }

                    .focusBtn{
                        font-size: 12px;
                        background: #999999;
                        border-radius: 12.5px;
                        padding: 4px 13px;
                        color: rgb(255,255,255);

                        display: flex;
                        justify-items: center;
                        align-items: center;
                    }

                    .alreadyFocus{
                        font-size: 12px;
                        background: rgb(113,69,231);
                        border-radius: 12.5px;
                        padding: 4px 7px;
                        color: rgb(255,255,255);
                    }

                }

                .communityText{
                    overflow: hidden;
                    -webkit-line-clamp: 3;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    margin-top: 17px;
                    padding: 0 12px;
                }

                .fullBtn{
                    font-size: 15px;
                    color: rgb(55,231,210);
                    margin-top: 6px;
                }

                .imgContent{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    margin-top: 3px;
                    padding-left: 12px;

                    .imgItem{
                        width: 111px;
                        height: 111px;
                        background: rgba(27,22,76,0.06);
                        border-radius: 6px;
                        margin-top: 9px;
                        margin-right: 9px;

                        /deep/ .van-image{
                            border-radius: 6px;
                        }

                        /deep/ .van-image__img{
                            border-radius: 6px;
                        }
                    }


                }

                .tags{
                    display: flex;
                    margin-top: 14px;
                    padding: 0 12px;
                    .tag{
                        font-size: 12px;
                        color: rgb(157,154,183);
                        margin-right: 18px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .bottomBar{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    //width: 100%;
                    margin-top: 14px;
                    font-size: 14px;
                    color: rgb(164,161,189);
                    padding: 0 12px;

                    .likeComments{
                        display: flex;
                        align-items: center;

                        .like{
                            display: flex;
                            align-items: center;

                            img{
                                width: 19px;
                                height: 17px;
                                margin-right: 3px;
                            }
                        }

                        .comments{
                            display: flex;
                            align-items: center;
                            margin-left: 14px;

                            img{
                                width: 19px;
                                height: 16px;
                                margin-right: 3px;
                            }
                        }
                    }
                }
            }

            .commentsContent{

                .commentsHead{
                    font-size: 18px;
                    color: rgb(0,0,0);
                    font-weight: 900;
                    margin-top: 18px;
                    padding-left: 12px;
                    display: flex;
                    align-items: flex-end;
                    .commentTotle {
                        color: #a4a1bd;
                        font-size: 12px;
                        margin-left: 12px;
                    }
                }

                .qiuckSearchBox {
                 color: #333333;
                 font-weight: 600;
                 font-size: 14px;
                 margin: 3px 10px 0 10px;
                 white-space: nowrap;
                 position: relative;

                 /deep/ .van-notice-bar__content {
                  color: #F92B6C !important;
                  font-weight: 600 !important;
                 }

                 /deep/ .van-notice-bar {
                  width: 100%;
                  padding: 0;
                 }

                 .qiuckSearchIcon {
                  height: 10px;
                  width: 10px;
                  position: absolute;
                  right: -10px;
                  top: 2px;
                 }
                }

                .commentsContentList{

                    padding: 18px 12px;

                    .msgItem {
                        margin-bottom: 26px;

                        .msgTopBox {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 6px;

                            .avatar {
                                width: 34px;
                                height: 34px;
                                margin-right: 18px;
                            }

                            .infoBox {
                                flex: 2;
                                color: #a09fb1;

                                .uName {
                                    font-size: 15px;
                                }

                                .dateInfo {
                                    font-size: 12px;
                                }
                            }

                            .likeBox {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                color: #a09fb1;
                                font-size: 12px;

                                .likeIcon {
                                    width: 16.2px;
                                    height: 14.8px;
                                    margin-bottom: 1.6px;
                                }
                            }
                        }

                        .msgContent {
                            margin-left: 52px;
                            color: rgb(0,0,0);
                        }

                        .msgLevel2 {
                            // width: 100%;
                            margin: 6px 0 0 52px;
                            border-radius: 6px;
                            padding: 9px;
                            box-sizing: border-box;
                            background: rgb(230,230,230);
                            font-size: 15px;

                            .msgBoxLv2 {
                                display: flex;
                            }

                            .nameLv2 {
                                // flex: 1;
                                color: rgb(0,0,0);
                                margin-right: 6px;
                            }

                            .msgLv2 {
                                flex: 2;
                                color: rgb(153,153,153);
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            .msgNum {
                                font-size: 15px;
                                color: #a09fb1;
                                margin-top: 6px;
                            }
                        }
                    }

                    //.commentsItem{
                    //
                    //    .userInfo{
                    //        display: flex;
                    //        padding: 0 12px;
                    //        margin-top: 18px;
                    //
                    //        .userPortrait{
                    //            width: 34px;
                    //            height: 34px;
                    //            background: rgb(255,255,255);
                    //            border-radius: 50%;
                    //        }
                    //
                    //        .userContent{
                    //            width: calc(100% - 34px - 18px);
                    //            display: flex;
                    //            justify-content: space-between;
                    //            margin-left: 18px;
                    //            font-size: 15px;
                    //            color: rgb(160,159,177);
                    //
                    //            .giveLike{
                    //                font-size: 12px;
                    //                color: rgb(160,159,177);
                    //                text-align: center;
                    //
                    //                img{
                    //                    width: 16.2px;
                    //                    height: 14.8px;
                    //                }
                    //            }
                    //        }
                    //    }
                    //
                    //    .commentsInfo{
                    //        padding:6px 12px 6px 64px;
                    //
                    //
                    //        .myComments{
                    //            font-size: 15px;
                    //            color: rgb(250,249,251);
                    //            overflow: hidden;
                    //            -webkit-line-clamp: 3;
                    //            text-overflow: ellipsis;
                    //            display: -webkit-box;
                    //            -webkit-box-orient: vertical;
                    //        }
                    //
                    //        .moreComments{
                    //            padding: 9px;
                    //            border-radius: 6px;
                    //            margin-top: 6px;
                    //            background: rgb(43,39,72);
                    //
                    //            .reply{
                    //                font-size: 15px;
                    //
                    //                .replyName{
                    //                    color: rgb(55,231,210);
                    //                    margin-right: 6px;
                    //                }
                    //
                    //                .replyComments{
                    //                    color: rgb(255,255,255);
                    //                }
                    //            }
                    //        }
                    //    }
                    //}
                }
            }
        }

        .tabBar{
            background: #FFFFFF;
            position: absolute;
            bottom: 0;
            height: 82px;
            width: 100%;
            z-index: 1;

            /deep/ .van-field__body {
             background: rgba(245, 245, 245, 1);
             box-shadow: 2px 2px 4px 0px rgba(174, 174, 192, 0.2) inset;
             padding: 6px 17px;
             border-radius: 8px;
            }

            .inputBox{
                display: flex;
                align-items: center;

                .sendBtn{
                    background: url("../../../assets/png/sendMsg.png");
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    width: 40px;
                    height: 40px;
                    margin-right: 10px;
                }
            }

            .comments{
                height: 59px;
                display: flex;
                justify-content: space-between;
                padding: 19px 24px;
                color: rgb(153,153,153);
                //font-weight: bold;
                font-size: 15px;

                img{
                    width: 18.2px;
                    height: 18.2px;
                }
            }

            .placeholder{
                height: 34px;
            }
        }
        .inputPop {
            .inputBox {
                height: 59px;
                padding: 0 12px;
                box-sizing: border-box;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .newInput {
                    width: 314px;
                    height: 36px;
                    border-radius: 10px;
                    padding: 0 12px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    background: rgba(245, 245, 245, 1);
                    box-shadow: -5px -5px 4px 0px rgba(255, 255, 255, 0.3) inset;
                 /deep/ .van-field__control {
                        font-size: 12px;
                        color: #000;
                    }

                    ::placeholder {
                        color: #999;
                    }
                }

                .sendBtn {
                    width: 66px;
                    height: 33px;
                    border-radius: 33px;
                    font-size: 15px;
                    color: #fff;
                    background: #757494;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .activeBtn {
                    color: #fff;
                    background: #7145e7;
                }
            }
        }
        .nodataBox {
            width: 100%;
            height: 140px;
            font-size: 18px;
            color: #44415b;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>
