<template>
  <div ref="textOverflow" class="text-overflow" :style="boxStyle">
    <img class="isShowTop" v-if="isTopping" src="@/assets/png/isTop.png" alt="">
    <span ref="overEllipsis" class="overEllipsis">{{ realText }}</span>
    <div class="slot-box" ref="slotRef" v-if="showSlotNode">
      <slot :click-toggle="toggle" :expanded="expanded"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    postInfo: {
      default: {},
    },
    maxLines: {
      type: Number,
      default: 3,
    },
    width: {
      type: Number,
      default: 0,
    },
    isPostItem: {
      type: Boolean,
      default: false,
    },
    isTopping: {
      type: Boolean,
      default: false,
    },
    defaultExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      offset: this.text.length,
      expanded: this.defaultExpanded,
      slotBoxWidth: 0,
      textBoxWidth: this.width,
      showSlotNode: false,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
    }),
    boxStyle() {
      if (this.width) {
        return {
          width: this.width + "px",
        };
      }
      return "0px";
    },
    realText() {
     if (this.expanded) {
      return this.text;
     }
     // 是否被截取
      const isCutOut = this.offset !== this.text.length;
      let realText = this.text;
      console.log(isCutOut, this.expanded);
      if (isCutOut && !this.expanded) {
        realText = this.text.slice(0, this.offset) + "...";
      }
      return realText;
    },
  },
  methods: {
    calculateOffset(from, to) {
      this.$nextTick(() => {
        if (Math.abs(from - to) <= 1) return;
        if (this.isOverflow()) {
          to = this.offset;
        } else {
          from = this.offset;
        }
        this.offset = Math.floor((from + to) / 2);
        this.calculateOffset(from, to);
      });
    },
    isOverflow() {
      const { len, lastWidth } = this.getLines();

      if (len < this.maxLines) {
        return false;
      }
      if (this.maxLines) {
        // 超出部分 行数 > 最大行数 或则  已经是最大行数但最后一行宽度 + 后面内容超出正常宽度
        const lastLineOver = !!(
          len === this.maxLines &&
          lastWidth + this.slotBoxWidth > this.textBoxWidth
        );
       return len > this.maxLines || lastLineOver;
      }
      return false;
    },
    getLines() {
      const clientRects = this.$refs.overEllipsis.getClientRects();
      return {
        len: clientRects.length,
        lastWidth: clientRects[clientRects.length - 1]?.width,
      };
    },
    toggle() {
      const { len } = this.getLines();
      if (len > this.maxLines) {
       this.showSlotNode = true;
       this.$nextTick(() => {
        this.slotBoxWidth = this.$refs.slotRef.clientWidth;
        this.textBoxWidth = this.$refs.textOverflow.clientWidth;
        this.calculateOffset(0, this.text.length);
       });
      }
      // if (this.isPostItem) {
        // if (this.postInfo.coins > 0 && !this.postInfo.vidStatus.hasPaid) {
        //   this.$bus.$emit("vipPopup", {
        //     id: this.postInfo.id,
        //     state: 2,
        //     goldenNum: this.postInfo.coins,
        //     closeBtn: () => {
        //       this.$bus.$emit("closeVipPopup");
        //     },
        //     buySuccess: () => {
        //       this.postInfo.vidStatus.hasPaid = true;
        //       this.expanded = !this.expanded;
        //       this.$bus.$emit("closeVipPopup");
        //     },
        //   });
        // } else {
        this.expanded = !this.expanded;
      //   // }
      // } else {
      //   this.expanded = !this.expanded;
      // }

      // if (this.isPostItem) {
      //   if (this.postInfo?.vidStatus?.hasPaid) {
      //     this.expanded = !this.expanded;
      //   } else if (this.postInfo.coins > 0) {
      //     this.$bus.$emit("vipPopup", {
      //       id: this.postInfo.id,
      //       state: 2,
      //       goldenNum: this.postInfo.coins,
      //       closeBtn: () => {
      //         // console.log(123123)
      //         this.$bus.$emit("closeVipPopup");
      //       },
      //       buySuccess: () => {
      //         this.postInfo.vidStatus.hasPaid = true;
      //         this.expanded = !this.expanded;
      //         this.$bus.$emit("closeVipPopup");
      //       },
      //     });
      //   } else if (this.userInfo.isVip) {
      //      this.expanded = !this.expanded;
      //   } else {
      //     this.$bus.$emit("vipPopup", {
      //       id: this.postInfo.id,
      //       state: 1,
      //       goldenNum: this.postInfo.coins,
      //       closeBtn: () => {
      //         this.$bus.$emit("closeVipPopup");
      //       },
      //       buySuccess: () => {
      //         this.postInfo.vidStatus.hasPaid = true;
      //         this.expanded = !this.expanded;
      //         this.$bus.$emit("closeVipPopup");
      //       },
      //     });
      //   }
      // } else {
      //   this.expanded = !this.expanded;
      // }
    },
  },
  mounted() {
    const { len } = this.getLines();
    if (len > this.maxLines) {
      this.showSlotNode = true;
      this.$nextTick(() => {
        this.slotBoxWidth = this.$refs.slotRef.clientWidth;
        this.textBoxWidth = this.$refs.textOverflow.clientWidth;
        this.calculateOffset(0, this.text.length);
      });
    }
  },
};
</script>

<style scoped>
.isShowTop {
 max-width: 32px !important;
 max-height: 16px !important;
 vertical-align: middle;
 margin-right: 4px;
}
.slot-box {
  /* display: inline-block; */
  font-size: 15px;
  color: #999999;
  /* padding-left: 5px; */
}

.overEllipsis {
  font-size: 15px;
  line-height: 24px;
  white-space: pre-wrap;
  vertical-align: middle;
}
</style>
